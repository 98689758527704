export class CustomerBaseData {
  custId: string;

  // email, mobile: -> Cognito
  // acc, dep, name, firstname -> Customer.ts (from Cognito via token)
  firstName: string;
  lastName: string;
  phone1: string;
  phone2: string;
  postalCode: string;
  city: string;
  salutation: string;
  street1: string;
  street2: string;
  streetNr: string;
  taxId: string;
  nvaValidFrom: Date;
  nvaValidTo: Date;
  vvtAktien: number;
  vvtSonstige: number;
  qstGuthaben: number;
  fsaAmount: number;
  fsaUsed: number;
  fsaLeft: number;
  fsaValidFrom: Date;
  fsaValidTo: Date;
  payedTaxAktien: number;
  payedTaxOther: number;
  kirchensteuersatz: number;
  kirchensteurKz: boolean;
  lastCsvUpdate: Date;
  birthdate: string;

  refAccOwnerFullName: string;
  refAccIban: string;
  refAccBic: string;
  refAccBank: string;

  refAccChangedTimestamp: Date;
  refAccChangedOwnerFullName: string;
  refAccChangedBic: string;
  refAccChangedIban: string;
  refAccChangedBank: string;
  refAccChangedNewDirectDebitMandate: boolean;

  orderCountLeveragedDerivatives: number;
  orderCountNonLeveragedDerivatives: number;
  orderCountKrypto: number;
  ftgPresent: boolean;
  personalPromoCode: string;
  otcConfirmed: Date;
  maritalStatus: string;
  adAgree: boolean;
  adAgreeChanged: Date;

  fzContractAcceptedDate: Date;
  fzContractActiveDate: Date;

  intermediaryId: string;
  optedInLowTrading: Date;

  customerId: string;
  depotId: string;
  depotReadyInfoMailSent: Date;
  taxCountry: string;
  country: string;
  additionalAddress: string;
  hasActiveWallet: boolean;
  identDocValidUntil: string;
  identDocValid: boolean;
  kryptoEnabled: boolean;
  lombardEnabled: boolean;

  multiOrderConfirmed: Date;
  customerClassification: 'BASIC' | 'ADVANCED';
}
