import { HistoricQuote } from './historic-quote.model';
import { InstrumentType } from './instrumenttype.enum';
import { ISODateTimeUTC } from './iso-date-time.model';
import { Quote } from './quote.model';

export class PositionData {
  id: number;
  isin: string;
  quantity: number;
  availableQuantity: number;
  avgEntryQuote: number;
  avgEntryQuoteEdited: number;
  avgEntryQuoteOriginal: number;
  quote: number;
  quoteTime: ISODateTimeUTC;
  depotId: string;
  assetName: string;
  type: InstrumentType;
  instrument: InstrumentDataForPosition;
  longName: string;
  shortName: string;

  // client-side calculated values
  perfPct: number;
  perfAbs: number;
  perfTodayPct: number;
  perfTodayAbs: number;
  entryValue: number;
  currentValue: number;
  historicQuote: HistoricQuote;

  getCurrentValue(): number {
    return this.quantity * this.quote;
  }

  getEntryValue(): number {
    return this.quantity * this.avgEntryQuote;
  }

  getPerfPct(): number {
    return ((this.getCurrentValue() - this.getEntryValue()) / this.getEntryValue()) * 100;
  }

  getPerfAbs(): number {
    return this.getCurrentValue() - this.getEntryValue();
  }

  getPerfTodayAbs(): number {
    if (!this.historicQuote?.bid) {
      return 0;
    }
    return (this.quote - this.historicQuote.bid) * this.quantity;
  }

  getPerfTodayPct(): number {
    if (!this.historicQuote?.bid) {
      return 0;
    }
    return ((this.quote - this.historicQuote.bid) / this.historicQuote.bid) * 100;
  }

  recalculate(): void {
    this.perfPct = this.getPerfPct();
    this.perfAbs = this.getPerfAbs();
    this.perfTodayAbs = this.getPerfTodayAbs();
    this.perfTodayPct = this.getPerfTodayPct();
    this.entryValue = this.getEntryValue();
    this.currentValue = this.getCurrentValue();
  }

  // used by trackByFn in ngFor
  getTrackByValue(): string {
    return this.isin;
  }
}

export class InstrumentDataForPosition {
  isin: string;
  type: InstrumentType;
  knockedOutFlag: boolean;
  endfaellig: boolean;
  longName: string;
  shortName: string;
  historicQuotes: HistoricQuote[];
}

export type GroupedPositionData = Record<string, (Position | InstrumentDataForPosition)[]>;

export type FilterGroup = InstrumentType | PositionFilterType;

export enum PositionFilterType {
  ALL = 'ALL',
  SAVINGS_PLAN = 'SAVINGS_PLAN',
  CATEGORIES = 'CATEGORIES',
}

export interface Sum {
  entryValue?: number;
  currentValue?: number;
  perfAbs?: number;
  perfPct?: number;
  perfTodayAbs?: number;
  perfTodayPct?: number;
  type?: FilterGroup;
}

export interface Position {
  id: number;
  isin: string;
  quantity: number;
  availableQuantity: number;
  avgEntryQuote: number;
  avgEntryQuoteEdited: number;
  avgEntryQuoteOriginal: number;
  quote: number;
  quoteTime: ISODateTimeUTC;
  depotId: string;
  assetName: string;
  type: InstrumentType;

  knockedOutFlag: boolean;
  endfaellig: boolean;
  longName: string;
  shortName: string;

  perfPct?: number;
  perfAbs?: number;
  perfTodayPct?: number;
  perfTodayAbs?: number;
  entryValue?: number;
  currentValue?: number;
  historicQuote?: HistoricQuote;
  quotePerfTodayAbs?: number;

  rawQuote: Quote;

  note?: PositionNote;
  isBuyable?: boolean;
  isSellable?: boolean;
  isSavingsPlanEligible?: boolean;
  orderExecutionTypesForProtection: OrderExecutionTypesForProtection[];
}

export interface PositionInstrument {
  isin: string;
  type: InstrumentType;
  knockedOutFlag: boolean;
  endfaellig: boolean;
  longName: string;
  shortName: string;
  historicQuotes: HistoricQuote[];
  sparplan: boolean;
}

export interface PositionsResponse {
  list: Position[];
  instruments: PositionInstrument[];
}

export interface PortfolioPerformance {
  currentValue: number;
  entryValue: number;
  perfTodayAbs: number;
  perfTodayPct: number;
  sumPerfPct: number;
  sumPerfAbs: number;
}

export interface PositionNote {
  id: number;
  version: number;
  depotId: string;
  isin: string;
  note: string;
  lastUpdate: Date;
}

export interface OrderExecutionTypesForProtection {
  orderExecutionType: string;
  protectedQuantityByOrderExecutionType: number;
}

export interface CustomEntryQuote {
  quote: string;
  isValid: boolean;
}

export interface CustomEntryQuotesRequest {
  isin: string;
  newAvgEntryQuote: number;
}

export interface PositionsSettings {
  notesVisible: boolean;
  protectionsVisible: boolean;
  dividendVisible: boolean;
}

export type CryptoSettings = PositionsSettings;
