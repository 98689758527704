import { ErrorHandler, provideAppInitializer } from '@angular/core';
import { Router } from '@angular/router';
import { browserTracingIntegration, createErrorHandler, init, replayIntegration, TraceService } from '@sentry/angular';
import { environment } from 'environments/environment';

export function provideSentry() {
  return [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      const initializerFn = (() => () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      })();
      return initializerFn();
    }),
  ];
}

export function initSentry() {
  environment.sentryDSN &&
    init({
      dsn: environment.sentryDSN,
      integrations: [browserTracingIntegration(), replayIntegration()],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', environment.apihost],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
}
