import { InstrumentType } from './instrumenttype.enum';
import { ISODateTimeUTC } from './iso-date-time.model';
import { OrderStatus } from './order-status.model';

export const OrderStatusMappingValues = {
  market: 'Markt',
  stop: 'Stop',
  stoplimit: 'Stop Limit',
  limit: 'Limit',
  buy: 'Kauf',
  sell: 'Verkauf',
  savings: 'Sparplan',
  fraction: 'Bruchstücke',
  oco: 'OCO',
  tsl: 'TSL',

  Created: 'entgegengenommen',
  Created_CreatedCancel: 'zur Streichung vorgemerkt',
  Created_SentCancel: 'zur Streichung vorgemerkt',
  Created_PendingCancel: 'vorläufig gestrichen',
  Sent: 'entgegengenommen',
  Sent_CreatedCancel: 'zur Streichung vorgemerkt',
  Sent_SentCancel: 'zur Streichung vorgemerkt',
  Sent_PendingCancel: 'vorläufig gestrichen',
  Pending: 'vorläufig',
  Pending_CreatedCancel: 'zur Streichung vorgemerkt',
  Pending_SentCancel: 'zur Streichung vorgemerkt',
  Pending_PendingCancel: 'vorläufig gestrichen',
  Open: 'offen',
  Open_CreatedCancel: 'zur Streichung vorgemerkt',
  Open_SentCancel: 'zur Streichung vorgemerkt',
  Open_PendingCancel: 'vorläufig gestrichen',
  Canceled: 'gestrichen',
  Rejected: 'zurückgewiesen',
  Expired: 'abgelaufen',
  PartiallyFilled: 'teilausgeführt',
  PartiallyFilled_CreatedCancel: 'teilausgeführt, zur Streichung vorgemerkt',
  PartiallyFilled_SentCancel: 'teilausgeführt, zur Streichung vorgemerkt',
  PartiallyFilled_PendingCancel: 'teilausgeführt, vorläufig gestrichen',
  PartiallyFilled_Canceled: 'teilausgeführt, gestrichen',
  PartiallyFilled_Expired: 'teilausgeführt, abgelaufen',
  PartiallyFilled_Filled: 'ausgeführt',
  Filled: 'ausgeführt',

  // tickets
  OPEN: 'Offen',
  PREPARED: 'Vorbereitet',
  CLOSED: 'Geschlossen',
  CUSTOMER_REPLY: 'bitte lesen',

  // savings plans
  monthly: 'Monatlich',
  biweekly: '2-Wöchentlich',
  weekly: 'Wöchentlich',
  quarterly: 'Quartalsweise',
  active: 'Aktiv',
  atwork: 'In Bearbeitung',
  deleted: 'Gelöscht',
  inactive: 'Inaktiv',
};

export interface OrderData {
  id: number;
  isin: string;
  quantity: number;
  openDate: ISODateTimeUTC;
  execDate: ISODateTimeUTC;
  cancelDate: ISODateTimeUTC;
  status: OrderStatus;
  execType: ExecutionType;
  limitPrice: number;
  stopPrice: number;
  direction: OrderDirection;
  validToDate: ISODate;
  execPrice: number;
  quote: number;
  blockedBP: number;
  orderId: string;
  trailingPriceAbs: number;
  trailingPricePct: number;
  assetName: string;
  openQuantity: number;
  executedQuantity: number;
  bpBlockedPrice: number;
  lastBackendUpdate: string;
  transactionKey: string;
  matchedImportStorno: string;
  rejectReason: string;
  minimumQuantitySurcharge: number;
  errorCode: string;
  orderValue: number;
  orderQuote: number;
  orderQuoteTime: string;
  instrumentType: InstrumentType;
  shortName: string;
  latestTslStop: number;
  fixedRemainingAmount: number;
  fractionalSurcharge: number;
  provisionFzEur?: number;
  provisionFzPct?: number;
  provisionBankEur?: number;
  provisionBankPct?: number;
}

export type ExecutionType = 'market' | 'limit' | 'stop' | 'stoplimit' | 'savings' | 'fraction' | 'oco' | 'tsl';
export type ISODate = string | '2018-08-31';
export type OrderDirection = 'buy' | 'sell' | 'sell_fraction';
