import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Asset } from '@zero/shared/util-models';
import { lowTradingThreshold as defaultLowTradingThreshold, minimumOrderValue } from 'constants/shared-constants';
import { environment } from 'environments/environment';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AssetCounts } from './entities/asset-counts.entity';
import { InstrumentSearch, InstrumentSearchResults } from './entities/instrument-search.entity';
import { InstrumentDTO } from './entities/instrument.entity';

@Injectable({
  providedIn: 'root',
})
export class AssetSearchService {
  private readonly _http = inject(HttpClient);

  private static getMinimumOrderValue(instrumentMinimumOrderValue: number): number {
    return instrumentMinimumOrderValue || minimumOrderValue;
  }

  #mapAssetData = ({
    type: instrumentType,
    leverage,
    complex,
    isin,
    wkn,
    longName: name,
    marketName,
    basisinformationen: baseInfoLink,
    verkaufsprospekt: salesbrochure,
    halbjahresbericht: semiAnnualReport,
    jahresbericht: annualReport,
    knockedOutFlag,
    endfaellig,
    tradingHoursStartTime,
    tradingHoursStopTime,
    emittentShortCode,
    sparplan,
    minimumOrderValue,
    lowTradingThreshold,
    lowTradingThresholdQuantity,
    underlyingIsin,
    underlying: underlyingName,
    longName,
    shortName,
    minQty,
    priceIncr,
    priceScale,
    qtyIncr,
    qtyScale,
    isOTC,
    disabledExecTypes,
    historicQuotes,
    quoteCurrencyCode,
    fractionalTrading,
    tradingFees,
  }: InstrumentDTO): Asset => {
    return {
      instrumentType,
      leverage,
      complex,
      isin,
      wkn,
      name,
      marketName,
      baseInfoLink,
      salesbrochure,
      semiAnnualReport,
      annualReport,
      knockedOutFlag,
      endfaellig,
      tradingHoursStartTime,
      tradingHoursStopTime,
      emittentShortCode,
      sparplan,
      minimumOrderValue: AssetSearchService.getMinimumOrderValue(minimumOrderValue),
      lowTradingThreshold: lowTradingThreshold ?? defaultLowTradingThreshold,
      lowTradingThresholdQuantity,
      underlyingIsin,
      underlyingName,
      longName,
      shortName,
      minQty,
      priceIncr,
      priceScale,
      qtyIncr,
      qtyScale,
      isOTC,
      disabledExecTypes,
      historicQuotes,
      quoteCurrencyCode,
      fractionalTrading,
      tradingFees,
    };
  };

  public loadAssetByIsin(isin: string): Observable<Asset> {
    return this._http.get<InstrumentDTO>(`${environment.apihost + environment.instrumentServiceUrl}/${isin}`).pipe(
      map((assetData: InstrumentDTO) => (assetData?.isin ? this.#mapAssetData(assetData) : ({} as Asset))),
      catchError(() => {
        return of(undefined);
      }),
    );
  }

  public loadAssetsByIsins(isins: string[]): Observable<Asset[]> {
    return this._http.post<InstrumentDTO[]>(environment.apihost + environment.instrumentServiceUrl, isins).pipe(
      map((instruments: InstrumentDTO[]) => (instruments?.length ? instruments.map((instrument) => this.#mapAssetData(instrument)) : [])),
      catchError(() => of(undefined)),
    );
  }

  public loadSearchResults = async (config?: AssetSearchConfiguration): Promise<InstrumentSearchResults> => {
    const url = this.createInstrumentSearchUrl(config);
    const { results, stats }: InstrumentSearch = await firstValueFrom(this._http.get<InstrumentSearch>(url));
    return {
      results,
      hits: stats?.hits?.HITS,
    };
  };

  public loadAssetCounts = async (): Promise<AssetCounts> => {
    return firstValueFrom(this._http.get<AssetCounts>(`${environment.assetshost}/assets/searchdata/instrument-counts.json`));
  };

  private createInstrumentSearchUrl = (config?: AssetSearchConfiguration): string => {
    let url = `${environment.apihost}/api/instrumentsearch?defaultAllTypes=true`;
    if (!config) {
      return url;
    }

    const { types, size, from, sparplan, query, fractional } = config;
    if (query) {
      url += `&q=${query}`;
    }

    if (types?.length) {
      url += `&type=${types.join(',')}`;
    }

    if (size) {
      url += `&size=${size}`;
    }

    if (from) {
      url += `&from=${from}`;
    }

    if (sparplan) {
      url += `&sparplan=true`;
    }

    if (fractional) {
      url += `&fractional=true`;
    }

    return url;
  };
}

export class AssetSearchConfiguration {
  public query?: string;
  public types?: string[];
  public size?: number;
  public from?: number;
  public sparplan?: boolean;
  public leverage?: boolean;
  public emittent?: string;
  public fractional?: boolean;
}
