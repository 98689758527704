import { dynamicValues } from './dynamic-values';

export const environment = {
  production: true,
  local: false,
  isTest: false,
  amplifyconfig: {
    Auth: {
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_W7ZDh3Al1',
      userPoolWebClientId: '79l53pdauo2ofvpnileh18am2i',
    },
  },
  apihost: 'https://mein.finanzen-zero.net',
  assetshost: 'https://mein.finanzen-zero.net',
  serverlessApihost: 'https://mein.finanzen-zero.net',
  apihost_ba: undefined,
  positionsUrl: '/api/trading/positions',
  push_url: 'gratisbroker.eu.diffusion.cloud',
  push_port: 443,
  instrumentServiceUrl: '/api/instrument',
  usernotes: '/api/notes',
  usernotesdismiss: '/api/notes/dismiss',
  tradingServiceUrl: '/api/trading',
  posteingangServiceUrl: '/api/posteingang',
  speechNr: '+31 97010241167',
  appAuthUrl: '/api/appauth',
  appEnrollmentUrl: '/api/appenrollment',
  ordersUrl: '/api/trading/orders',
  moneytxUrl: '/api/moneytransaction',
  watchlistUrl: '/api/wl',
  fcmPushAdminUrl: '/api/mobileapp/pushadmin',
  notificationServiceUrl: '/api/notification',
  confirmLowTradingUrl: '/api/user/confirmlowtrading',
  confirmOtcTradingUrl: '/api/user/confirmotctrading',
  ticketsServiceUrl: '/api/ticket',
  ticketdialog: '/api/ticketdialog',
  savingsplanApi: '/api/savingsplan',
  versionCheckApi: '/api/versions/check',
  featureFlagsUrl: 'app-config',
  instrumentNews: '/api/instrument-cache/news',
  corporateActions: {
    ankuendigung: '/api/ca/ankuendigung',
    weisungVorschau: '/api/ca/weisungvorschauanfordern',
    weisungErteilen: '/api/ca/weisungerteilen',
  },
  externalLinks: {
    website: 'https://www.finanzen.net/zero',
    impressum: 'https://www.finanzen.net/zero/impressum',
    faqs: 'https://support.finanzen-zero.net/hc/de',
    fairUsePolicy: 'https://www.finanzen.net/zero/fair-use-policy',
    plv: 'https://www.finanzen.net/zero/wp-content/uploads/2021/05/finanzennetzero_Preis-Leistungsverzeichnis.pdf',
    nutzungsbedingungen: 'https://www.finanzen.net/zero/wp-content/uploads/2021/05/finanzennetzero_Nutzungsvereinbarung.pdf',
    datenschutz: 'https://www.finanzen.net/zero/datenschutz/',
    facebook: 'https://www.facebook.com/GRATISBROKER/',
    youtube: 'https://www.youtube.com/channel/UClUjCHaj7IFfsnsHsww5R5A',
    twitter: 'https://twitter.com/gratisbroker',
    depotEroeffnen: 'https://mein.finanzen-zero.net/depot-eroeffnen/registrieren?utm_source=zeroapp&utm_medium=btn_depoteroeffnen',
    sicherheitshinweise: 'https://www.finanzen.net/zero/sicherheitshinweise',
    formulare: 'https://mein.finanzen-zero.net/verwaltung',
    securetandescription: 'https://www.finanzen.net/zero/securetan',
    webapplication: 'https://mein.finanzen-zero.net',
    administration: 'https://mein.finanzen-zero.net/verwaltung',
    depotUebertrag: 'https://mein.finanzen-zero.net/depotwechsel-starten',
    disclaimer: 'https://www.finanzen.net/zero/disclaimer',
  },

  buildVersion: dynamicValues.buildVersion,
  mockhttp: false,
  authServiceUrl: '/api/auth',
  apiLoginUrl: '/api/auth/login',
  tokenRefreshUrl: '/api/auth/tokenrefresh',
  awsRegion: 'eu-central-1',
  pregenerateRegistrationPdfUrl: '/api/user/registrationpdf',
  mobilenrCheckUrl: '/api/user/checkmobilenr',
  customerIdentificationUrl: 'https://go.idnow.de/',
  postIdentificationIbanDisplayUrl: '/api/user/account/iban',
  ticketUrl: '/api/ticket',
  googleAnalyticsTrackingId: 'UA-151345905-1',
  changeFsaPreviewPdfUrl: '/api/ticket/fsapreview',
  toggleAdAgreeUrl: '/api/user/toggleadagree',
  loginCount: '/api/user/lc',
  cognitoEndpointHost: undefined,
  cognitoEndpointPath: '/apic/authc',
  oAuth2Endpoints: {
    authorize: '/api/oauth2/authorize',
    token: '/api/oauth2/token',
    authenticate: '/api/oauth2/authenticate',
    publicKey: '/api/oauth2/key',
    session: '/api/oauthsession',
  },
  usersettings: '/api/usersettings',
  stats: '/api/stats',
  sitedomain: 'https://mein.finanzen-zero.net',
  sendAuthHeaderForApi: false,
  useLocalStorageToStoreTokens: false,
  contentPageUrl: 'https://www.finanzen.net/zero/',
  instrumentsFileLocation: '/assets/searchdata/instruments.csv',
  instrumentSearch: '/api/instrumentsearch',
  checkNewClientVersion: true,
  logoutUrl: 'https://www.finanzen.net/zero/logout/',
  portfolioTransferUrl: 'https://depotwechsel.finanzen-zero.net/',
  finDataUrl: 'https://mein.finanzen-zero.net/extapis/findata',
  traderfoxApi: '/apic/hebel',
  isInstrumentDetailFeatureActive: true,
  isBezugsrechtFeatureActive: true,
  shakeToDebug: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBZhbGyls7gCT-w6XKcg0fOI2x_rTD6DaA',
    authDomain: 'gratisbroker-de.firebaseapp.com',
    databaseURL: 'https://gratisbroker-de.firebaseio.com',
    projectId: 'gratisbroker-de',
    storageBucket: 'gratisbroker-de.appspot.com',
    messagingSenderId: '849204390780',
    appId: '1:849204390780:web:2a3a68917c08a876c552f9',
    measurementId: 'G-ZX6TMNX4F2',
  },
  googleMapsKey: 'AIzaSyCnWKM3xggIeTR0Yqx2EXiamkoMZU0dk8s',
  sourcePoint: {
    web: {
      privacyManagerId: '608973',
      propertyHref: 'https://mein.finanzen-zero.net',
      accountId: 1817,
    },
  },
  postIdentMobileSdk: 'e8fa7eebc06b45a3858269742000ea51',
  postIdentTU: false,
  shouldDisplayAppVersion: false,
  disableTax: false,
  awsWafChallengePlugin: 'aws.waf.challenge.p1',
  gtm: {
    id: 'GTM-NJ8WSLX',
    transportUrl: 'https://metrics.finanzen-zero.net',
  },

  cleverPush: {
    channelIdWeb: 'GPacWkqhPK5utpamw',
    channelIdMobile: '7xE5SFeK3TAGLaqPg',
  },
  directDebitEnabled: true,
  multiOrderStrategiesEnabled: true,
  derivativeHighlightedProductAd: 'UBS',
  sentryDSN: '',
};
